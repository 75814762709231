import PdfExport from './PdfExport.js';
import GridFeatureManager from '../GridFeatureManager.js';
import PrintMixin from './mixin/PrintMixin.js';
/**
 * @module Grid/feature/export/Print
 */
/**
 * Allows printing Grid contents using browser print dialog.
 *
 * This feature is based on {@link Grid.feature.export.PdfExport} with only difference that instead of sending request to a
 * backend it renders content to an IFrame element and requests print dialog for it. For more details about preparing
 * HTML for printing, please refer to the {@link Grid.feature.export.PdfExport} docs.
 *
 * ## Usage
 *
 * ```javascript
 * const grid = new Grid({
 *     features : {
 *         print : true
 *     }
 * })
 *
 * // Opens popup allowing to customize print settings
 * grid.showPrintDialog();
 *
 * // Simple print
 * grid.print({
 *     columns : grid.columns.map(c => c.id)
 * });
 * ```
 *
 * This feature is **disabled** by default.
 * For info on enabling it, see {@link Grid/view/mixin/GridFeatures}.
 *
 * @extends Grid/feature/export/PdfExport
 * @mixes Grid/feature/export/mixin/PrintMixin
 *
 * @demo Grid/print
 * @classtype print
 * @feature
 */
export default class Print extends PrintMixin(PdfExport) {
    /**
     * @hideConfigs clientURL, exportServer, fetchOptions, fileFormat, fileName, openAfterExport, openInNewTab, sendAsBinary
     */
    /**
     * @hideFunctions processExportContent, receiveExportContent, showExportDialog
     */
    static $name = 'Print';
}
Print._$name = 'Print'; GridFeatureManager.registerFeature(Print, false, 'Grid');
